import {
    Component,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    ViewEncapsulation,
    signal,
    ViewChild,
} from "@angular/core";
import { Debounce } from "../../../../decorators/debounce.decorator";
import { EventService } from "../../../../services/event.service";
import { PopoverComponent } from "../../../s25-popover/popover.component";
import { S25EventOccurrencesService } from "../s25.event.occurrences.service";

/**
 * Displays gear icon, lets the user select between 'seperated' and 'merged view'. migration of evdo-menu
 * OTHER NOTES:
 * Commented out "Delete This Segment"  event micro service current not support this feature
 * ACCL-1752 getting very large, don't want adding any new functionality
 *
 */
@Component({
    selector: "s25-ng-occ-menu",
    template: `
        <div class="occ-menu-wrapper" [class.single-profile]="!multiProfiles">
            @if (multiProfiles && ((isSeparatedView && isFirstSegment) || !isSeparatedView)) {
                <div class="occ-menu">
                    <button
                        class="view-select-button c-margin-right--half"
                        [ngClass]="{
                            'aw-button aw-button--primary': !isSeparatedView,
                            'c-textButton': isSeparatedView,
                        }"
                        (click)="onViewChange()"
                    >
                        Merged View
                    </button>
                    <button
                        class="view-select-button"
                        [ngClass]="{
                            'aw-button aw-button--primary': isSeparatedView,
                            'c-textButton': !isSeparatedView,
                        }"
                        (click)="onViewChange()"
                    >
                        Separated View
                    </button>
                </div>
            }
            @if (canEdit) {
                <s25-popover
                    [modelBean]="{ popoverTemplate: newSegmentForm }"
                    [openTrigger]="'click'"
                    [closeTrigger]="'click'"
                >
                    <button class="aw-button aw-button--outline" (click)="addSegment()">Add a New Segment</button>
                </s25-popover>
            }

            @if (canEdit && isSeparatedView) {
                <!--  <div class="occ-menu" (click)="copySegment()">Copy This Segment</div> -->
                @if (multiProfiles) {
                    <button class="aw-button aw-button--danger--outline" (click)="deleteSegment()">
                        Delete This Segment
                    </button>
                }
            }
            @if (!multiProfiles || (isSeparatedView && isFirstSegment) || !isSeparatedView) {
                <button class="aw-button aw-button--outline" (click)="expandAllToggle()">
                    {{ isExpandAll ? "Collapse All" : "Expand All" }}
                </button>
            }
        </div>

        <ng-template #newSegmentForm>
            <div class="date-time-section">
                @if (profileName()) {
                    <s25-ng-editable-text
                        class="segmentName"
                        [allowEmpty]="false"
                        [min]="2"
                        [max]="40"
                        [alwaysEditing]="true"
                        [val]="profileName()"
                        (valChange)="profileName.set($event)"
                    ></s25-ng-editable-text>

                    <s25-ng-editable-start-end-datetime
                        [startDatetime]="startDate"
                        (startDatetimeChange)="onDateTimeChange($event, 'start')"
                        [endDatetime]="endDate"
                        (endDatetimeChange)="onDateTimeChange($event, 'end')"
                        [noTimeUpdated]="true"
                    >
                    </s25-ng-editable-start-end-datetime>
                    <button class="aw-button aw-button--outline c-margin-top--half" (click)="onSubmit(true)">
                        Submit
                    </button>
                }
            </div>
        </ng-template>
    `,
    styles: [
        `
            .occ-menu-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-block: 15px;
            }

            .occ-menu-wrapper.single-profile {
                margin-block: 1rem;
            }

            .occ-menu {
                font-size: 16px !important;
                font-weight: 600;
            }

            .nm-party--on {
                .occ-menu {
                    color: #fff;
                }

                .c-start-end-datetime-picker--wrapper {
                    box-shadow: unset !important;
                }
            }

            .view-select-button.aw-button.aw-button--primary {
                font-size: unset !important;
                border-radius: 7px;
            }

            .date-time-section {
                padding: 10px 20px 20px 20px;
            }
        `,
    ],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvOccMenuComponent {
    @Input() isSeparatedView: boolean;
    @Input() profileId?: number;
    @Input() canEdit?: boolean = false;
    @Input() multiProfiles?: boolean = false;
    @Input() startDate?: Date;
    @Input() endDate?: Date;
    @Input() isFirstSegment: boolean = false;
    @Output() modelValueChange: EventEmitter<EvOccMenuOutput> = new EventEmitter<EvOccMenuOutput>();

    @ViewChild(PopoverComponent) popover: PopoverComponent;

    init: boolean = false;
    profileName = signal<string>("");
    newProfileId: number;
    isExpandAll?: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private eventOccurrencesService: S25EventOccurrencesService,
    ) {}

    @Debounce(300) // Avoid double calls
    copySegment() {
        this.isSeparatedView = true;
        this.modelValueChange.emit({
            isSeparatedView: this.isSeparatedView,
            addNew: true,
            data: { profileId: this.profileId, startDate: this.startDate, endDate: this.endDate },
        });
    }

    //go to seperated view and indicate that a new segment is added
    async addSegment() {
        this.newProfileId = await EventService.getNewProfileId();
        this.profileName.set("Segment_" + this.newProfileId);
    }

    @Debounce(300) // Avoid double calls
    onSubmit(addNew: boolean) {
        if (addNew) {
            const data = {
                startDate: this.startDate,
                endDate: this.endDate,
                profileName: this.profileName(),
                profileId: this.newProfileId,
            };
            this.isSeparatedView = true;
            this.modelValueChange.emit({ isSeparatedView: this.isSeparatedView, addNew: true, data: data });
            this.popover?.close();
        }
    }

    onDateTimeChange(newDate: Date, text: "start" | "end") {
        text === "start" ? (this.startDate = newDate) : (this.endDate = newDate);
    }

    onViewChange() {
        const data = {
            profileId: this.profileId,
        };
        this.isSeparatedView = !this.isSeparatedView;
        this.modelValueChange.emit({ isSeparatedView: this.isSeparatedView, addNew: false, data: data });
    }

    @Debounce(300) // Avoid double calls
    deleteSegment() {
        this.isSeparatedView = true;
        this.modelValueChange.emit({
            isSeparatedView: this.isSeparatedView,
            addNew: false,
            data: { profileId: this.profileId },
            isDelete: true,
        });
    }

    refresh() {
        this.init = false;
        this.cd.detectChanges();
        this.init = true;
        this.cd.detectChanges();
    }

    expandAllToggle() {
        this.isExpandAll = !this.isExpandAll;
        this.eventOccurrencesService.setExpandAll(!this.isExpandAll);
        const elements: any = document.querySelectorAll(".occ-expand-all");
        for (let i = 0; i < elements.length; ++i) {
            elements[i].click();
        }
        this.cd.detectChanges();
    }
}

export type EvOccMenuOutput = {
    isSeparatedView: boolean;
    data?: {
        profileId?: number;
        profileName?: string;
        startDate?: Date;
        endDate?: Date;
    };
    addNew: boolean;
    isDelete?: boolean;
};
